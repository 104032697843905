"use strict";
$(document).ready(function () {
  /*----------------------------------------
     Pre Loader
     ----------------------------------------*/
  $(window).on('load', function () {
    $('.loader-wrapper').fadeOut('slow');
    $('.loader-wrapper').remove('slow');
  });
});
